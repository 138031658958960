import SearchQueryRepository from "../Repository/SearchQueryRepository";

export default class SearchQuery {
  constructor(object) {
    this.id = object.id;
    this.query = object.query;
    this.createdAt = object.createdAt;
  }

  /**
     *
     * @param {object} objectList
     * @returns {SearchIndex[]}
     */
  static fromList(objectList = []) {
    if(!objectList) {
      return objectList;
    }
    if(objectList.length === 0) return objectList;
    return objectList.map(o => new SearchQuery(o));
  }

  static async searchQuery(query) {
   return await SearchQueryRepository.searchQuery(query);
  }
}