// eslint-disable-next-line
import SearchIndex from "../../Model/SearchIndex";
import AppMath from "../../Util/AppMath";

export default class PrometheusAlgorithm {
  static highestLevenshteinDistance = 0;
  static lowestLevenshteinDistance = 0;

  static getStopWords() {
    const englishStopWords = [
      "the", "and", "in", "of", "for", "on", "with", "to", "at", "by",
      "as", "a", "an", "is", "are", "be", "it", "this", "that", "or"
    ];
  
    const turkishStopWords = [
      "ve", "ile", "bir", "bu", "o", "için", "da", "de", "ama", "gibi",
      "kadar", "ya", "ama", "yani",  "hem", "ise", 
    ];
  
    const frenchStopWords = [
      "le", "la", "les", "des", "du", "un", "une", "en", "et", "ou",
      "mais", "avec", "pour", "dans", "sur", "sous", "par", "à", "qui", "que"
    ];

    // TODO add more language
    // you should no load everything, prefer to get the client language instead

    return [
      ...englishStopWords,
      ...turkishStopWords,
      ...frenchStopWords
    ];
  }

  /**
   * 
   * @param {SearchIndex[]} indexList
   */
  static run(indexList = [], query) {
    console.log('run');

    // here we should run the scoring functions
    indexList = indexList.map((searchIndex) => {
      this.calculateTitleRelevancy(searchIndex, query);
      this.calculateDescriptionRelevancy(searchIndex, query);
      this.calculateUrlRelevancy(searchIndex, query);

      // this.calculateTitleSEOScore(searchIndex);
      // this.calculateDescriptionSEOScore(searchIndex);
      // this.calculateUrlSEOScore(searchIndex);
      return searchIndex;
    });

    indexList.sort((a, b) => {
      return a.searchScore < b.searchScore ? 1 : -1;
    });

    return indexList;
  }

  static calculateTitleRelevancy(searchIndex, query) {
    if (searchIndex.title === undefined) {
      // no title, we don't want to show this ever
      searchIndex.searchScore = 0;
    } else if (searchIndex.title.trim() === '') {
      // no title, we don't want to show this ever
      searchIndex.searchScore = 0;
    } else {
      // Convert both the title and query to lowercase for case-insensitive comparison
      const trimmedTitle = searchIndex.title.trim();
      const titleWords = new Set(trimmedTitle.toLowerCase().split(' '));
      const queryLower = query.toLowerCase();
      const queryWords = new Set(queryLower.split(' '));

      let searchIndexScore = 0;

      // Iterate over each word in the queryWords set
      for (const queryWord of queryWords) {
        // Check if the current queryWord exists in the titleWords set
        if (titleWords.has(queryWord)) {
          // Match found, increment searchIndexScore
          searchIndexScore++;
        }
      }

      // Update searchIndex.searchScore with the total score
      searchIndex.searchScore += searchIndexScore;
      searchIndex.searchScore -= AppMath.levenshteinDistance(queryLower, trimmedTitle) / 1000;
      // searchIndex.searchScore += AppMath.bm25(queryLower, trimmedTitle);
    }
  }

  /**
   * 
   * @param {SearchIndex} searchIndex 
   * @param {String} query 
   * @returns {void}
   */
  static calculateDescriptionRelevancy(searchIndex, query) {
    if (searchIndex.description === undefined) {
      searchIndex.searchScore = 0;
      return 0; // because it is irrelevant result
    } else if (searchIndex.description.trim() === '') {
      searchIndex.searchScore = 0;
      return 0; // because it is irrelevant result
    } else {
      const trimmedDescription = searchIndex.description.trim();

      // // SEO: check the description length
      // if (trimmedDescription.length > 150 && trimmedDescription.length < 160) {
      //   // good
      //   searchIndex.searchScore += 2;
      // } else {
      //   // not in the desired range
      //   searchIndex.searchScore -= 1;
      // }

      // Convert both the description and query to lowercase for case-insensitive comparison
      const descriptionWords = trimmedDescription.toLowerCase().split(' ');
      const queryLower = query.toLowerCase();
      const queryWords = queryLower.split(' ');

      // Calculate the number of matching words in the description
      let matchingWords = 0;
      for (const queryWord of queryWords) {
        if (descriptionWords.includes(queryWord)) {
          matchingWords++;
        }
      }

      if (matchingWords === queryWords.length) {
        // this is super match
        searchIndex.searchScore += 3;
      } else if (matchingWords > 0 && matchingWords <= queryWords.length) {
        // this is good match
        searchIndex.searchScore += 2;
      } else if (matchingWords > 0 && matchingWords > queryWords.length) {
        // this is a spam, because the same words has been used many times
        searchIndex.searchScore += 1;
      } else if (matchingWords === 0) {
        // this is probably not a good match
        searchIndex.searchScore += 0;
      }

      searchIndex.searchScore -= AppMath.levenshteinDistance(queryLower, trimmedDescription) / 1000;
      //searchIndex.searchScore += AppMath.bm25(queryLower, trimmedDescription);
    }
  }

  /**
   * 
   * @param {SearchIndex} searchIndex 
   * @param {String} query 
   * @returns {void}
   */
  static calculateUrlRelevancy(searchIndex, query) {
    const url = searchIndex.url.toLowerCase();
    const urlWords = url.split(/[/.?&=-]/);
    const queryLower = query.toLowerCase();
    const queryWords = new Set(queryLower.split(' '));

    let searchIndexScore = 0;

    // Create an object to store the words from urlWords
    const urlWordsObj = {};
    for (const word of urlWords) {
      urlWordsObj[word] = true;
    }

    // Iterate over each word in the queryWords set
    for (const queryWord of queryWords) {
      // Check if the current queryWord exists in the urlWordsObj
      if (urlWordsObj[queryWord]) {
        // Match found, increment searchIndexScore
        searchIndexScore++;
      }
    }

    // Update searchIndex.searchScore with the total score
    searchIndex.searchScore += searchIndexScore;
    searchIndex.searchScore -= AppMath.levenshteinDistance(queryLower, url) / 1000;
    // searchIndex.searchScore += AppMath.bm25(queryLower, url);
   
  }



  static calculateTitleSEOScore(searchIndex) {
    // Convert the title to lowercase for consistent analysis
    const title = searchIndex.title.toLowerCase();

    // Define a list of stop words (common words that may not be SEO-relevant)
    const stopWords = this.getStopWords();

    // Tokenize the title into words
    const words = title.split(' ');

    // Calculate the total length of the title
    const titleLength = title.length;

    // Calculate the number of stop words in the title
    const stopWordsCount = words.filter(word => stopWords.includes(word)).length;

    // Calculate the SEO score based on factors (you can adjust these factors as needed)
    // In this example, we're giving a higher score for longer titles and penalizing for stop words
    const maxTitleLength = 70; // Adjust this based on your SEO guidelines
    const lengthScore = Math.min((titleLength / maxTitleLength) * 100, 100);
    const stopWordsPenalty = ((stopWordsCount / words.length) * 100);

    // Combine the scores with your chosen weights to get the overall SEO score
    // Adjust the weights as needed for your specific SEO criteria
    const lengthWeight = 0.7; // Adjust these weights as needed
    const stopWordsWeight = 0.3;
    const overallSEOScore = (lengthWeight * lengthScore) + (stopWordsWeight * (100 - stopWordsPenalty));

    return overallSEOScore;
  }

  static calculateDescriptionSEOScore(searchIndex) {
    if (searchIndex.description === undefined) {
      return -3;
    }
    // Convert the description to lowercase for consistent analysis
    const description = searchIndex.description.toLowerCase();

    // Define a list of stop words (common words that may not be SEO-relevant)
    const stopWords = this.getStopWords();

    // Tokenize the description into words
    const words = description.split(' ');

    // Calculate the total length of the description
    const descriptionLength = description.length;

    // Calculate the number of stop words in the description
    const stopWordsCount = words.filter(word => stopWords.includes(word)).length;

    // Calculate the SEO score based on factors (you can adjust these factors as needed)
    // In this example, we're giving a higher score for longer descriptions and penalizing for stop words
    const maxDescriptionLength = 160; // Adjust this based on your SEO guidelines
    const lengthScore = Math.min((descriptionLength / maxDescriptionLength) * 100, 100);
    const stopWordsPenalty = ((stopWordsCount / words.length) * 100);

    // Combine the scores with your chosen weights to get the overall SEO score
    // Adjust the weights as needed for your specific SEO criteria
    const lengthWeight = 0.7; // Adjust these weights as needed
    const stopWordsWeight = 0.3;
    const overallSEOScore = (lengthWeight * lengthScore) + (stopWordsWeight * (100 - stopWordsPenalty));

    return overallSEOScore;
  }

  static calculateUrlSEOScore(searchIndex) {
    // Convert the URL to lowercase for consistent analysis
    const url = searchIndex.url.toLowerCase();

    // Define a list of stop words (common words that may not be SEO-relevant)
    const stopWords = this.getStopWords();

    // Split the URL into parts by slashes and remove empty parts
    const urlParts = url.split('/').filter(part => part.trim() !== '');

    // Join the URL parts with spaces for further analysis
    const urlText = urlParts.join(' ');

    // Tokenize the URL text into words
    const words = urlText.split(/\W+/);

    // Calculate the total length of the URL
    const urlLength = urlText.length;

    // Calculate the number of stop words in the URL
    const stopWordsCount = words.filter(word => stopWords.includes(word)).length;

    // Check if the URL contains keywords (you can customize this based on your SEO strategy)
    const hasKeywords = /keyword1|keyword2|keyword3/i.test(url);

    // Calculate the SEO score based on factors (you can adjust these factors as needed)
    // In this example, we're giving a higher score for shorter URLs, the presence of keywords, and no stop words
    const maxLength = 100; // Adjust this based on your SEO guidelines
    const lengthScore = Math.min((1 - urlLength / maxLength) * 100, 100);
    const keywordsScore = hasKeywords ? 100 : 0;
    const stopWordsPenalty = ((stopWordsCount / words.length) * 100);

    // Combine the scores with your chosen weights to get the overall SEO score
    // Adjust the weights as needed for your specific SEO criteria
    const lengthWeight = 0.4; // Adjust these weights as needed
    const keywordsWeight = 0.4;
    const stopWordsWeight = 0.2;
    const overallSEOScore = (lengthWeight * lengthScore) + (keywordsWeight * keywordsScore) - (stopWordsWeight * stopWordsPenalty);

    return overallSEOScore;
  }

}

