import ApiFetch from "../Api/ApiFetch";
import ApiPrefix from "../Api/ApiPrefix";
import Seo from "../Model/Seo";

export default class SeoRepository {


  static async getSeo(path = '/', webinifyApp) {

    try {
      let url = new URL(ApiPrefix.getByEnv('PUBLIC') + "/seos");
      url.searchParams.set('WebinifyApp[]', webinifyApp.id);
      url.searchParams.set('path[]', path);
      
      let response = await ApiFetch.public(url, {
        method: "GET"
      });

      if (response.ok) {
        const json = await response.json();

        return Seo.fromList(json);
  
      }
    } catch (e) {
      console.log(e);
      return [Seo.defaultSeo()];
    }
  }

}