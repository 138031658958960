

export default class SearchImageIndex {
    constructor(object) {
        this.loc = object.loc;
        this.caption = object.caption;
    }


    /**
       *
       * @param {object} objectList
       * @returns {SearchImageIndex[]}
       */
    static fromList(objectList = []) {
        if (objectList.length === 0) return objectList;
        return objectList.map(o => new SearchImageIndex(o));
    }

}