
import WebinifyApp from "../Model/WebinifyApp";
import ApiPrefix from "../Api/ApiPrefix";
import ApiFetch from "../Api/ApiFetch";
import Constants from "../Constants";

export default class WebinifyAppRepository {

  /**
   *
   * @returns {Promise<WebinifyApp[]>}
   */
  static async getAll() {

    let url = new URL(ApiPrefix.getByEnv('PUBLIC') + "/webinify_apps");

    const json = await ApiFetch.cachedPublic(url, {
      method: "GET",
    }, {
      cacheExpirationTime: Constants.fiveMinutes
    });

    const objects = WebinifyApp.fromList(json);
    return objects;
  }



}