import React from "react";
import AnonymousController from "../Common/Controller/AnonymousController";
import SearchIndex from "../Model/SearchIndex";
import AppHeader from "../Component/AppHeader/AppHeader";
import SearchIndexWidget from "../Component/SearchIndex/SearchIndexWidget";
import './SearchPage.scss';
import Seo from "../Common/Model/Seo";
import Constants from "../Common/Constants";
import AppEvent from "../Events/AppEvent";
import { FaImages, FaNewspaper, FaSearch, FaVideo } from "react-icons/fa";
import TranslateUtility from "../Common/Utility/TranslateUtility";
import SearchNewsIndexWidget from "../Component/SearchIndex/SearchNewsIndexWidget";

export default class SearchPage extends AnonymousController {
    constructor(props) {
        super(props);

        this.paginatorConfig = {
            counterDistance: window.innerWidth < 767 ? 3 : 5
        }

        this.state = {
            searchedString: "",
            results: [],
            chunkedResults: [],
            separatedResults: [],
            chunkedSeparatedResults: [],
            time: 0,
            resultsCount: 0,
            keywords: [],
            page: 1,
            chunkLength: 0

        }
    }

    componentDidMount() {
        AppEvent.init();
        this.loadResult();
    }

    async loadResult() {
        const res = await SearchIndex.searchQuery(SearchIndex.getQueryFromUrl());
        const keywords = SearchIndex.getQueryKeyWords(SearchIndex.getQueryFromUrl());
        const chunkedResults = this.chunkResults(res.results);
        const currentPage = SearchIndex.getPageFromUrl();
        const separatedResults = res.separated_results;
        const chunkedSeparatedResults = this.chunkResults(res.separated_results);

        this.setState({
            results: res.results,
            time: res.time,
            count: res.count,
            keywords: keywords,
            chunkedResults: chunkedResults,
            chunkLength: chunkedResults.length,
            page: currentPage,
            searchedString: SearchIndex.getQueryFromUrl(),
            separatedResults: separatedResults,
            chunkedSeparatedResults: chunkedSeparatedResults
        });
    }

    renderResultSearch(results = []) {
        return results.map((r, index) => <SearchIndexWidget key={index} result={r} keywords={this.state.keywords} ></SearchIndexWidget>);
    }

    renderNewsResultSearch(results = []) {
        return results.map((r, index) => <SearchNewsIndexWidget key={index} result={r} keywords={this.state.keywords} ></SearchNewsIndexWidget>);
    }

    chunkResults(results = [], chunkSize = 7) {

        const chunkedArray = [];
        for (let i = 0; i < results.length; i++) {
            const last = chunkedArray[chunkedArray.length - 1];
            if (!last || last.length === chunkSize) {
                chunkedArray.push([results[i]]);
            } else {
                last.push(results[i]);
            }
        };

        return chunkedArray;
    }

    onPageClick(e, index) {
        let queryString = new URL(window.location.href);
        queryString.searchParams.set("page", index);
        window.location.href = queryString;
    }

    renderPaginationButtons(page = 1) {
        const toggleDisplay = (index) => {
            if (index >= page) {
                if (index - page < this.paginatorConfig.counterDistance) {
                    return 1
                }
                return 0;
            } else if (page >= index) {
                if (page - index < this.paginatorConfig.counterDistance) {
                    return 1
                }
                return 0;
            }

        }
        let list = [
            ...(page - 1 < 1 ? '' : [
                <button
                    key={'__first__'}
                    className="paginationButton"
                    onClick={(e) => this.onPageClick(e, 1)}
                    data-index={1}
                    display={1}
                > {'<<'} </button>,
                <button
                    key={'__previous__'}
                    className="paginationButton"
                    onClick={(e) => this.onPageClick(e, page - 1)}
                    data-index={page - 1}
                    display={1}
                > {'<'} </button>,
            ]),
            ...this.state.chunkedResults.map((e, index) => <button
                key={index + 1}
                className="paginationButton"
                onClick={(e) => this.onPageClick(e, index + 1)}
                data-index={index + 1}
                is-current={page === index + 1 ? 1 : 0}
                display={toggleDisplay(index + 1)}
            >{index + 1}</button>),
            ...(
                page + 1 > this.state.chunkedResults.length ? '' :
                    [
                        <button
                            key={'__next__'}
                            className="paginationButton"
                            onClick={(e) => this.onPageClick(e, page + 1)}
                            data-index={page + 1}
                            display={1}
                        > {'>'} </button>,

                        <button
                            key={'__latest__'}
                            className="paginationButton"
                            onClick={(e) => this.onPageClick(e, this.state.chunkedResults.length)}
                            data-index={this.state.chunkedResults.length}
                            display={1}
                        > {'>>'} </button>
                    ]
            )
        ];


        return list;

    }


    _renderSearchTypes() {

        let searchTypes = [
            {
                type: 'all',
                text: TranslateUtility.t('All'),
                icon: < FaSearch />,
                active: false,
                activeFeature: true,
                isActive: function (obj) {
                    const url = new URL(window.location.href);
                    if (url.searchParams.has('type')) {
                        if (url.searchParams.get('type') === obj.type) {
                            return 1;
                        }
                        return 0;
                    } else {
                        return 1;
                    }
                },
                onClick: function (obj) {
                    let url = new URL(window.location.href);
                    url.searchParams.set('type', obj.type);
                    url.searchParams.delete('page');

                    window.location.href = url.href;

                }
            },
            {
                type: 'images',
                text: TranslateUtility.t('Images'),
                icon: < FaImages />,
                active: false,
                activeFeature: false,
                isActive: function (obj) {
                    const url = new URL(window.location.href);
                    if (url.searchParams.has('type')) {
                        if (url.searchParams.get('type') === obj.type) {
                            return 1;
                        }
                        return 0;
                    } else {
                        return 0;
                    }
                },
                onClick: function (obj) {
                    let url = new URL(window.location.href);
                    url.searchParams.set('type', obj.type);
                    url.searchParams.delete('page');
                    window.location.href = url.href;

                }
            },
            {
                type: 'videos',
                text: TranslateUtility.t('Videos'),
                icon: < FaVideo />,
                active: false,
                activeFeature: false,
                isActive: function (obj) {
                    const url = new URL(window.location.href);
                    if (url.searchParams.has('type')) {
                        if (url.searchParams.get('type') === obj.type) {
                            return 1;
                        }
                        return 0;
                    } else {
                        return 0;
                    }
                },
                onClick: function (obj) {
                    let url = new URL(window.location.href);
                    url.searchParams.set('type', obj.type);
                    url.searchParams.delete('page');
                    window.location.href = url.href;

                }
            },
            {
                type: 'news',
                text: TranslateUtility.t('News'),
                icon: < FaNewspaper />,
                active: false,
                activeFeature: false,
                isActive: function (obj) {
                    const url = new URL(window.location.href);
                    if (url.searchParams.has('type')) {
                        if (url.searchParams.get('type') === obj.type) {
                            return 1;
                        }
                        return 0;
                    } else {
                        return 0;
                    }
                },
                onClick: function (obj) {
                    let url = new URL(window.location.href);
                    url.searchParams.set('type', obj.type);
                    url.searchParams.delete('page');

                    window.location.href = url.href;

                }
            }
        ];

        // searchTypes = searchTypes.filter((searchType) => searchType.activeFeature === true);
        searchTypes.forEach((searchType) => {
            searchType.active = searchType.isActive(searchType);
        });

        return <div className="searchTypes">
            <ul>
                {searchTypes.map((object, index) =>
                    <li key={index}
                        onClick={() => object.onClick(object)}
                        type={object.type}
                        is-active={object.active}>{object.icon} {object.text} {object.activeFeature !== true ? "(Beta)" : ""}</li>)}
            </ul>
        </div>
    }

    _renderResultCounter() {
        if (this.state.count) {
            return <div className="timer">
                {TranslateUtility.t(`results_found`, {
                    'count': this.state.count,
                    'time': this.state.time,
                })}
            </div>
        }
    }


    // Here you will render only some 5 latest news
    _renderTopNews() {

    }

    // here you will render only 5 videos
    _renderTopVideos() {
    }

    // here you will render the page of images
    _renderImagesPage(results = []) {
        let images = results.map((res) => <a key={res.id} href={res.url}>
            <img src={res.images[0].loc} alt='pic'></img>
            <h3>{res.publisher}</h3>
            <p>{res.title}</p>
        </a>);

        return <div className="images-container">
            {images}
        </div>
    }

    // here you will render the page of news
    _renderNewsPage() {
        return <>
                <div className="SearchResults">
                    <div className="Results">
                        {this.renderNewsResultSearch(this.state.chunkedResults[this.state.page - 1])}
                    </div>
                </div>
                <div className="SearchPagination">
                    {this.renderPaginationButtons(this.state.page)}
                </div>
        </>
    }

    // here you will render the page of videos
    _renderVideosPage(videos = []) {
        let videoItems = videos.map((video) => (
            <div className="video-container" key={video.id}>
                {/* You can use an iframe for embedding YouTube videos or other site videos */}
                <iframe
                    title={video.title}
                    src={video.url}
                    allowFullScreen
                    frameBorder="0"
                ></iframe>
                <h3>{video.title}</h3>
                <p>{video.description}</p>
            </div>
        ));

        return <div className="videos-container">{videoItems}</div>;
    }


    // render all the results
    _renderAllResults() {

        return <>
            <div className="SearchResults">
                <div className="Results">
                    {this.renderResultSearch(this.state.chunkedResults[this.state.page - 1])}
                </div>
                {this.state.separatedResults.length > 0 ? <div className="Results separated">
                    {this.renderResultSearch(this.state.chunkedSeparatedResults[this.state.page - 1])}
                </div> : ''}


            </div>
            <div className="SearchPagination">
                {this.renderPaginationButtons(this.state.page)}
            </div>
        </>
    }

    _renderNoResult() {
        return <div className="no-results-message">{TranslateUtility.t('no_results')}</div>;
    }

    // this will show the right page content
    _pageContentRenderer() {
        if (this.state.count === 0) {
            // if no results, show message
            return this._renderNoResult();
        }

        const url = new URL(window.location.href);
        if (url.searchParams.has('type')) {
            const type = url.searchParams.get('type');
            if (type === 'all') {
                return this._renderAllResults(this.state.chunkedResults[this.state.page - 1]);
            } else if (type === 'videos') {
                return this._renderVideosPage(this.state.chunkedResults[this.state.page - 1]);
            } else if (type === 'images') {
                return this._renderImagesPage(this.state.chunkedResults[this.state.page - 1]);
            } else if (type === 'news') {
                return this._renderNewsPage(this.state.chunkedResults[this.state.page - 1]);
            } else {
                return this._renderAllResults(this.state.chunkedResults[this.state.page - 1]);
            }

        } else {
            return this._renderAllResults(this.state.chunkedResults[this.state.page - 1]);
        }
    }

    render() {
        return super.render({
            meta: new Seo({
                title: this._getQueryStringSafe() + " - " + Constants.AppTitle
            }),
            customHeader: <AppHeader // TODO: TO be removed once the account system is ready
                withAppListing={false} // we dont want the app listing
                withHeaderAuthenticatedButtonWidget={false} // we dont want the account system
            />,
            inviewToRender: <div className="SearchPage">
                {this._renderSearchTypes()}
                {this._renderResultCounter()}
                {this._pageContentRenderer()}

            </div>
        })
    }


    _getQueryStringSafe() {
        // Get the full URL
        var url = window.location.href;

        // Use the URLSearchParams API to safely parse the query string
        var searchParams = new URLSearchParams(new URL(url).search);

        // Get the 'q' parameter from the query string
        var queryString = searchParams.get('q');

        // Escape any special characters to ensure security
        var sanitizedQueryString = this._escapeHtml(queryString);

        return sanitizedQueryString;
    }

    _escapeHtml(unsafe) {
        return unsafe.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    }

}