
import PrometheusAlgorithm from "./v2/PrometheusAlgorithm";


export default class SearchAlgorithm {

    /**
     * In this function, you need to choose which algorithm you wanna use
     * @param {SearchIndex[]} indexList 
     * @returns {SearchIndex[]}
     */
    static run(indexList = [], query) {
        return PrometheusAlgorithm.run(indexList, query);
    }
}