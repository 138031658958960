import React from "react";
import "./SearchBarComponent.scss";
import SearchQueryPreviewComponent from "./SearchQueryPreviewComponent/SearchQueryPreviewComponent";
import SearchQueryRepository from "../Repository/SearchQueryRepository";
import SearchIndex from "../Model/SearchIndex";
import { FaSearch } from "react-icons/fa";
import TranslateUtility from "../Common/Utility/TranslateUtility";

export default class SearchBarComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            preload: props.preload ?? false,
            previewList: [],
            searchQuery: null,
            showSearchButton: props.showSearchButton ?? false
        }

        this.searchQueryPreviewElement = React.createRef();
    }

    onSearchButtonClicked = async () => {
        if (this.state.searchQuery != null && this.state.searchQuery.trim().length > 0) {
            await this.performSearch(this.state.searchQuery);
        }
    }

    performSearch = async (value) => {
        value = value.replace(/\s+/g, '+');
        if (this.state.preload === true) {
            await SearchIndex.searchQuery(value);
        }
        // you need to save it too
        SearchQueryRepository.addToLocal(value);
        window.location.href = '/search?q=' + value;
    }

    onKeyUp = async (event) => {
        this.setState({
            searchQuery: event.currentTarget.value
        });
    }

    onKeyDown = async (event) => {
        if (event.keyCode === 13) {
            let value = event.currentTarget.value;
            await this.performSearch(value);
        } else {
            let value = event.currentTarget.value;
            value = value.trim();

            if (value.split('').length > 0) {
                value = value.replace("+", ' ');
                // const response = await SearchQuery.searchQuery(value);
                const response = await SearchQueryRepository.searchQueryFromLocalHistory(value);
                this.searchQueryPreviewElement.current.updateList(response);
            } else {
                // const response = await SearchQueryRepository.searchQueryFromLocalHistory(null);
                // this.searchQueryPreviewElement.current.updateList(response);
                // this.searchQueryPreviewElement.current.updateList();
            }
        }
    }

    removeQueryFromHistory = async (value) => {
        SearchQueryRepository.removeFromLocal(value);
        const response = await SearchQueryRepository.searchQueryFromLocalHistory(value);
        this.searchQueryPreviewElement.current.updateList(response);

    }

    getQueryFromUrl() {
        let paramString = window.location.href.split('?')[1];
        let queryString = new URLSearchParams(paramString);
        let value = queryString.get('q');
        return value;
    }

    handleBlur = () => {
        setTimeout(() => {
            this.searchQueryPreviewElement.current.updateList([]);
        }, 100);
        // Additional logic you want to perform when the input loses focus
    };

    handleFocus = async (event) => {
        let value = event.currentTarget.value;
        if(value) {
            value = value.replace("+", ' ');
            // const response = await SearchQuery.searchQuery(value);
            const response = await SearchQueryRepository.searchQueryFromLocalHistory(value);
            this.searchQueryPreviewElement.current.updateList(response);
        }
    
    }

    render() {
        return (
            <>
                <div className="SearchBarComponent">
                    <div className="input">
                        <FaSearch />
                        <input
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            onKeyDown={this.onKeyDown}
                            onKeyUp={this.onKeyUp}
                            className="SearchBar"
                            defaultValue={this.getQueryFromUrl()}></input>
                    </div>

                    <SearchQueryPreviewComponent ref={this.searchQueryPreviewElement} onRemove={this.removeQueryFromHistory}></SearchQueryPreviewComponent>
                </div>
                {this.state.showSearchButton ? <button onClick={this.onSearchButtonClicked} className="search-now">{TranslateUtility.t("search_on_button")}</button> : <></>}
            </>
        )
    }
}