

export default class AppMath  {

    /**
     * 
     * @param {String} query 
     * @param {String} text 
     * @returns 
     */
    static levenshteinDistance(query, text) {
        const matrix = [];
    
        // Initialize the matrix with the first row and column representing insertion and deletion costs
        for (let i = 0; i <= query.length; i++) {
            matrix[i] = [i];
        }
        for (let j = 0; j <= text.length; j++) {
            matrix[0][j] = j;
        }
    
        // Fill in the rest of the matrix
        for (let i = 1; i <= query.length; i++) {
            for (let j = 1; j <= text.length; j++) {
                // Calculate costs for insertion, deletion, and substitution
                const insertionCost = matrix[i][j - 1] + 1;
                const deletionCost = matrix[i - 1][j] + 1;
                const substitutionCost = matrix[i - 1][j - 1] + (query[i - 1] !== text[j - 1] ? 1 : 0);
                
                // Choose the minimum cost
                matrix[i][j] = Math.min(insertionCost, deletionCost, substitutionCost);
            }
        }
    
        // Return the Levenshtein distance (cost in the bottom right cell of the matrix)
        return matrix[query.length][text.length];
    }
    

    static bm25(query, document, k1 = 1.5, b = 0.75) {
        // Tokenize query and document
        const queryTerms = query.toLowerCase().split(/\s+/);
        const documentTerms = document.toLowerCase().split(/\s+/);
        
        // Calculate average document length
        const avgDocumentLength = documentTerms.length;
    
        // Calculate document frequency for each term in the query
        const documentFrequencies = {};
        queryTerms.forEach(term => {
            const documentFrequency = documentTerms.filter(word => word === term).length;
            documentFrequencies[term] = documentFrequency;
        });
    
        // Initialize BM25 score
        let score = 0;
    
        // Calculate BM25 score for each term in the query
        queryTerms.forEach(term => {
            // Calculate term frequency in the document
            const termFrequency = documentTerms.filter(word => word === term).length;
    
            // Calculate term's IDF
            const documentFrequency = documentFrequencies[term];
            const idf = Math.log((documentTerms.length - documentFrequency + 0.5) / (documentFrequency + 0.5));
    
            // Calculate term's BM25 score
            const numerator = termFrequency * (k1 + 1);
            const denominator = termFrequency + k1 * (1 - b + b * (avgDocumentLength));
            const termScore = idf * (numerator / denominator);
    
            // Accumulate term's score to the total score
            score += termScore;
        });
    
        return score;
    }
    
}