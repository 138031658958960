import ApiFetch from "../Api/ApiFetch";
import ApiPrefix from "../Api/ApiPrefix";
import Constants from "../Constants";


export default class GeoLocationService {

    static async geoLocate() {
        let url = new URL(ApiPrefix.getByEnv('APIS') + "/geolocation/v1/geolocate");
        url.searchParams.set('key', Constants.WebinifyCloudGeoApiKey);

        const jsonData = await ApiFetch.cachedPublic(url, {
            method: 'GET',
        }, {
            cacheExpirationTime: Constants.fiveMinutes
        });

        if (jsonData) {
            return jsonData;
        }
    }

}