import GeoLocationService from "../Common/Service/GeoLocationService";
import BrowserUtility from "../Common/Utility/BrowserUtility";
import CookieStorage from "../Common/Utility/Storage/CookieStorage";
import LocalStorageUtility from "../Common/Utility/Storage/LocalStorageUtility";


export default class AppEvent {

    // Run this always when initiating a page
    static async init() {
        // browser languages
        const countryCode = BrowserUtility.browserLanguage.split('-')[1];
        const browserLanguage = BrowserUtility.browserLanguage.split('-')[0];
        LocalStorageUtility.set('X-Geo-Country-Code', countryCode);
        LocalStorageUtility.set('X-Geo-Client-Lang', browserLanguage);

        CookieStorage.set('X-Geo-Country-Code', countryCode);
        CookieStorage.set('X-Geo-Client-Lang', browserLanguage);
        CookieStorage.safeSet('country', countryCode);
        CookieStorage.safeSet('lang', browserLanguage);
        

        // here launch the geolocation API
        const geoData = await GeoLocationService.geoLocate();
        if(geoData) {
            LocalStorageUtility.set('X-Geo-User-Ip', geoData.ipAddress);
            CookieStorage.set('X-Geo-User-Ip', geoData.ipAddress);
            CookieStorage.set('ip', geoData.ipAddress);
        }
    }
    
}