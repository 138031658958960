
export default class SearchDiscovery {
  constructor(object) {
    this.id = object.id;
    this.url = object.url;
  }


  /**
     *
     * @param {object} objectList
     * @returns {SearchIndex[]}
     */
  static fromList(objectList = []) {
    if (objectList.length === 0) return objectList;
    return objectList.map(o => new SearchDiscovery(o));
  }

}