import SearchIndexRepository from "../Repository/SearchIndexRepository";
import SearchImageIndex from "./SearchImageIndex";

export default class SearchIndex {
  constructor(object) {
    this.id = object.id;
    this.title = object.title;
    this.description = object.description;
    this.url = object.url;
    this.searchScore = object.searchScore ?? 0;
    this.images = SearchImageIndex.fromList(object.images);
    this.publisher = object.newsName;
    this.publicationDate = object.newsPublicationDate;
  }


  /**
     *
     * @param {object} objectList
     * @returns {SearchIndex[]}
     */
  static fromList(objectList = []) {
    if(objectList.length === 0) return objectList;
    return objectList.map(o => new SearchIndex(o));
  }

  static getQueryFromUrl() {
    let url = new URL(window.location.href);
    let value = url.searchParams.get("q");
    return value;
  }

  static getPageFromUrl() {
    let url = new URL(window.location.href);
    let value = url.searchParams.get("page") || 1;
    if(isNaN(value)) {
      return 1;
    }
    return parseInt(value);
  }


  static getQueryKeyWords(query) {
    let keywords = query.split(' ');
    return keywords;
  }

  static async searchQuery(query, page = 1) {
    return await SearchIndexRepository.searchQuery(query, page);
  }
}