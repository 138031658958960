
export default class ArrayUtility {

    static recursiveMerge(target, ...sources) {
        if (!sources.length) {
            return target;
        }

        const source = sources.shift();

        for (const key in source) {
            if (source.hasOwnProperty(key)) {
                if (source[key] instanceof Object && target[key] instanceof Object) {
                    target[key] = ArrayUtility.recursiveMerge(target[key], source[key]);
                } else {
                    target[key] = source[key];
                }
            }
        }

        return ArrayUtility.recursiveMerge(target, ...sources);
    }

    static async asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }
}