

export default class DateHandler {

    static formatDate(date, format = 'dd/mm/yyyy') {
        let _date = new Date(date);
        return _date.toLocaleDateString()
    }

    /**
     * 
     * @param {string} dateString for ex: "2024-07-19T21:56:22+00:00"
     * @returns 
     */
    static getElapsedTime(dateString) {
        // Parse the date string into a Date object
        const date = new Date(dateString);
    
        // Get the current date and time
        const now = new Date();
    
        // Calculate the difference in milliseconds
        const elapsedMilliseconds = now - date;
    
        // Convert the difference into more readable units
        const seconds = Math.floor(elapsedMilliseconds / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30); // Approximate
        const years = Math.floor(months / 12);
    
        return {
            years,
            months: months % 12,
            days: days % 30,
            hours: hours % 24,
            minutes: minutes % 60,
            seconds: seconds % 60
        };
    }
    
}