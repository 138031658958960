import React from "react";
import CommonHeader from "../../Common/Widget/Header/CommonHeader";
import HeaderAuthenticatedButtonWidget from "../../Common/Widget/HeaderAuthenticatedButtonWidget/HeaderAuthenticatedButtonWidget";
import SearchBarComponent from "../SearchBarComponent";
import "./AppHeader.scss";

export default class AppHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            withAppListing: props.withAppListing ?? true,
            withHeaderAuthenticatedButtonWidget: props.withHeaderAuthenticatedButtonWidget ?? true,
            isSearchBarInHeader: props.isSearchBarInHeader || this.isSearchPath(),
        }
    }

    isSearchPath() {
        return window.location.pathname === '/search';
    }

    render() {
        return <CommonHeader
            isLogo={this.isSearchPath()}
            isMenu={this.isSearchPath()}
            content={<SearchBarComponent />}
            className="AppHeader"
            withAppListing={this.state.withAppListing}

            showOtherActions={this.state.withHeaderAuthenticatedButtonWidget === true ? <HeaderAuthenticatedButtonWidget /> : <></>}
        />
    }
}