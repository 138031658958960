
import SearchAlgorithm from "../Algorithm/SearchAlgorithm.js";
import ApiFetch from "../Common/Api/ApiFetch";
import ApiPrefix from "../Common/Api/ApiPrefix";
import SearchIndex from "../Model/SearchIndex";
import LocalStorageUtility from '../Common/Utility/Storage/LocalStorageUtility.js';

/**
 * This is the repository where you can get all the indexed results
 */
export default class SearchIndexRepository {

  static async searchQuery(query, page = 1) {

    const clientUrl = new URL(window.location.href);

    let url = new URL(ApiPrefix.getByEnv('SEARCH') + "/api/public/search/search_indices");
    url.searchParams.set('query', query);
    if (clientUrl.searchParams.has('lang')) {
      url.searchParams.set('query', clientUrl.searchParams.get('lang'));
    }
    if (clientUrl.searchParams.has('type')) {
      const type = clientUrl.searchParams.get('type');

      if ([
        'all', 'videos', 'images', 'news'
      ].includes(type)) {
        url.searchParams.set('type', type);
      }
    }

    //url.searchParams.set('page', page);

    const json = await ApiFetch.cachedPublic(url, {
      method: "GET",
      headers: {
        'X-Geo-Country-Code': LocalStorageUtility.get('X-Geo-Country-Code'),
        'X-Geo-Client-Lang': LocalStorageUtility.get('X-Geo-Client-Lang'),
        'X-Geo-User-Ip': LocalStorageUtility.get('X-Geo-User-Ip')
      }
    }, {
      cacheExpirationTime: 60 * 1000 * 5 // 5 minutes
    });

    return {
      time: json.time,
      count: json.count,
      results: SearchAlgorithm.run(SearchIndex.fromList(json.results), query),
      separated_results: SearchAlgorithm.run(SearchIndex.fromList(json.separated_results), query),
    };
  }

}