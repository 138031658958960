


export default class CookieStorage {

    // Function to add a cookie
    static set(name, value, days = 180) {
        let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + value + expires + "; path=/";
    }

    // Function to delete a cookie
    static remove(name) {
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    // Function to get the value of a cookie by key
    static get(key) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(key + '=')) {
                return cookie.substring(key.length + 1);
            }
        }
        return null;
    }

    // Function to check if a cookie with the given key exists
    static has(key) {
        return document.cookie.split(';').some(cookie => cookie.trim().startsWith(key + '='));
    }

    static safeSet(key, value) {
        if (!CookieStorage.has(key)) {
            return CookieStorage.set(key, value);
        } 
        return false;
    }
}