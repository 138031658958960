
import ApiFetch from "../Common/Api/ApiFetch";
import ApiPrefix from "../Common/Api/ApiPrefix";
import SearchDiscovery from "../Model/SearchDiscovery";


export default class SearchDiscoveryRepository {


  /**
   * 
   * @param {integer} quantity 
   * @returns {Promise<SearchDiscovery[]|null>}
   */
  static async findAll(quantity = 5, autoProcess = true) {
    let url = new URL(ApiPrefix.getByEnv('SEARCH') + "/api/bot-auth/search_discoveries");
    url.searchParams.set('quantity', quantity);
    url.searchParams.set('auto_process', autoProcess ? 1 : 0);

    const response = await ApiFetch.public(url, {
      method: "GET"
    });

    if(response.ok) {
      const json = await response.json();
      return SearchDiscovery.fromList(json.discoveries);
    }

    
  }


  static async save(discovery) {
    let url = new URL(ApiPrefix.getByEnv('SEARCH') + "/bot-auth/search_discoveries");

    const response = await ApiFetch.public(url, {
      method: 'POST',
      body: JSON.stringify(discovery)
    });
    
    return response;

  }

}