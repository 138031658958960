import React from "react";
import AnonymousController from "../Common/Controller/AnonymousController";
import Seo from "../Common/Model/Seo";
import "./ScorePage.scss";
import AppEvent from "../Events/AppEvent";
import SearchDiscoveryRepository from "../Repository/SearchDiscoveryRepository";
import ArrayUtility from "../Common/Utility/ArrayUtility";


export default class ScorePage extends AnonymousController {
    constructor(props) {
        super(props);

        // Create a URLSearchParams object with the query parameters of the current URL
        const params = new URLSearchParams(new URL(window.location.href).search);

        // Get the value of the "q" parameter
        const qValue = params.get('q');

        this.state = {
            meta: {},
            counter: 0,
            quantity: qValue ?? 10 

        }
    }

    componentWillMount() {
        AppEvent.init();

        let meta = new Seo({
            id: 0,
            title: "Counter Game",
            description: ""
        });
        this.setState({ meta: meta });

        this.start();
    }

    render() {
        return super.render({
            meta: this.state.meta,
            customHeader: <></>, // this should be removed later
            inviewToRender: <div className="app">
               <h1>{this.state.counter}</h1>
                
            </div>
        })
    }

    async start() {
        // first of all get all urls to index
        const searchDiscoveries = await SearchDiscoveryRepository.findAll(this.state.quantity, false);

        await ArrayUtility.asyncForEach(searchDiscoveries, async () => {
            // foreach searchIndex, make another request

            // get the metatags

            // save it

            // update the counter
            const count = this.state.counter + 1;
            this.setState({ counter: count }, () => {
                console.log("Counter after update:", this.state.counter);
            });

            // once the loop is over, restart the loop
        });

        // then for each of them
    }
}