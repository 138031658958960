import React from "react";
import "./SearchQueryPreviewComponent.scss";
import { FaHistory } from "react-icons/fa";

export default class SearchQueryPreviewComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            previewList: [],
        }

        this.onRemove = props.onRemove;
    }

    updateList = (list = []) => {
        this.setState({
            previewList: list
        });
    }

    removeFromHistory(e, query) {
        e.preventDefault(); // Prevent the default behavior of the <a> tag
        this.onRemove(query);
    }

    renderPreviewList() {
        return this.state.previewList.map(p => <a key={p.id} href={`/search?q=${p.query.replace(/\s+/g, '+')}`} >
            <FaHistory />
            <span>{p.query.replace('+', ' ')}</span>
            <button onClick={(e) => this.removeFromHistory(e, p.query)}>X</button>
        </a>);
    }

    render() {
        const previewList = this.renderPreviewList();
        const show = previewList.length > 0 ? true : false;
        return (
            <div className="SearchQueryPreviewComponent" data-show={show}>
                {previewList}
            </div>
        )
    }
}