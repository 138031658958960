import SearchQuery from "../Model/SearchQuery";

import ApiPrefix from "../Common/Api/ApiPrefix";
import ApiFetch from "../Common/Api/ApiFetch";
import LocalStorageUtility from "../Common/Utility/Storage/LocalStorageUtility";

export default class SearchQueryRepository {


  static async searchQuery(query) {

    let url = new URL(ApiPrefix.getByEnv('SEARCH') + "/api/public/search/queries");
    url.searchParams.set('query', query);

    let json = await ApiFetch.cachedPublic(url, {
      method: "GET",
    }, {
      cacheExpirationTime: 60 * 1000 // 1 minute
    });

    return SearchQuery.fromList(json);
  }

  static async searchQueryFromLocalHistory(value) {

    const json = LocalStorageUtility.getSafeJson('search-query-history');
    if (json) {
      let searchQueries = SearchQuery.fromList(json);
      if (value) {
        // Use a custom filter function to find queries that start with the given value
        return searchQueries.filter((searchQuery) => {
          // Check if the query starts with the specified value (case-insensitive)
          const query = searchQuery.query.toLowerCase();
          const searchTerm = value.toLowerCase();
          return query.startsWith(searchTerm);
        });
      }
      return searchQueries;
    }
    return [];
  }


  static addToLocal(query) {
    const jsonHistory = LocalStorageUtility.getSafeJson('search-query-history');
    let searchQueries = SearchQuery.fromList(jsonHistory);
    if (searchQueries) {
      const hasQuery = searchQueries.some((searchQuery) => searchQuery.query === query);
      if (!hasQuery) {
        const searchQuery = new SearchQuery({ query: query });
        searchQueries.push(searchQuery);
        LocalStorageUtility.set('search-query-history', JSON.stringify(searchQueries));
      }

    }
  }

  static removeFromLocal(query) {
    const jsonHistory = LocalStorageUtility.getSafeJson('search-query-history');
    let searchQueries = SearchQuery.fromList(jsonHistory);
    if (searchQueries) {
      const filteredSearchQueries = searchQueries.filter((searchQuery) => searchQuery.query !== query);
      LocalStorageUtility.set('search-query-history', JSON.stringify(filteredSearchQueries));
    }
  }

}