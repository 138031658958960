export default class LocalStorageUtility {

    static getSafeJson(key) {
        const data = LocalStorageUtility.getJson(key);
        if(data) {
            return data;
        } else {
            LocalStorageUtility.set(key, JSON.stringify([]));
            return LocalStorageUtility.getJson(key);
        }
    }

    static getJson(key) {
        const value = localStorage.getItem(key);
        if (value) {
            const json = JSON.parse(value);
            return json;
        }
        return null;
    }

    static set(key, value) {
        return localStorage.setItem(key, value);
    }

    static setIfEmpty(key, value) {
        if(!localStorage.getItem(key)) {
            return localStorage.setItem(key, value);
        }
    }

    static get(key) {
        return localStorage.getItem(key);
    }

    static getCachedData(key, expirationTime) {
        const cachedData = localStorage.getItem(key);
        // const cachedData = sessionStorage.getItem(key);

        if (cachedData) {
            const { timestamp, data } = JSON.parse(cachedData);
            if (Date.now() - timestamp < expirationTime) {
                return data;
            }
        }
        return null;
    }

    static cacheData(key, data) {
        const cachedData = JSON.stringify({
            timestamp: Date.now(),
            data: data
        });
        localStorage.setItem(key, cachedData);
    }

    static deleteData(key) {
        localStorage.removeItem(key);
    }

    static clear() {
        localStorage.clear();
    }
}