import React from "react";
import "./SearchIndexWidget.scss"


export default class SearchIndexWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            result: props.result,
            keywords: props.keywords
        }

        this.onClick = this.onClick.bind(this);
    }

    replaceKeyWords() {
        let description = this.state.result.description;

        if (description) {
            this.state.keywords.forEach(word => {
                const regex = new RegExp(word, 'gi'); // 'gi' for case-insensitive global search
                description = description.replace(regex, `<strong>${word}</strong>`);
            });

            // Limit description length and ensure HTML tags are not split
            const truncatedDescription = description.slice(0, 140);

            // Check if the last index of '<' is less than the last index of '>'
            // to ensure closing tags are not split
            const lastIndexOfOpeningTag = truncatedDescription.lastIndexOf('<');
            const lastIndexOfClosingTag = truncatedDescription.lastIndexOf('>');

            if (lastIndexOfOpeningTag > lastIndexOfClosingTag) {
                // If the last index of '<' is greater than the last index of '>',
                // then there is an unclosed tag, so we find the last index of '>' after the last index of '<'
                const nextClosingTagIndex = truncatedDescription.indexOf('>', lastIndexOfOpeningTag);
                if (nextClosingTagIndex !== -1) {
                    // If a closing tag is found, truncate the description until that closing tag
                    description = truncatedDescription.slice(0, nextClosingTagIndex + 1);
                }
            } else {
                // If all tags are properly closed, use the truncated description
                description = truncatedDescription;
            }

            description += "...";
        }

        return description;
    }

    onClick = (e) => {
        e.preventDefault();
        const url = this.state.result.url;
        window.location.href = url;
    }


    render() {
        return <div className="SearchIndexWidget">
            <a className="link" onClick={this.onClick} href={this.state.result.url}>
                <p className="linkName">{this.state.result.url}</p>
                <h4 className="title">{this.state.result.title}</h4>
            </a>

            <p className="description" dangerouslySetInnerHTML={{ __html: this.replaceKeyWords() }}></p>

        </div>

    }
}