import React from "react";
import AnonymousController from "../Common/Controller/AnonymousController";
import Seo from "../Common/Model/Seo";
import AppLogo from "../Common/Widget/Logo/AppLogo";
import SearchBarComponent from "../Component/SearchBarComponent";
import "./HomePage.scss";
import AppEvent from "../Events/AppEvent";

const {
    VERSION_TAG,
  } = process.env;

export default class HomePage extends AnonymousController {
    constructor(props) {
        super(props);


        this.state = {
            meta: {}
        }
    }

    componentDidMount() {
        AppEvent.init();
        Seo.getSeoMeta().then(meta => {
            this.setState({ meta: meta });
        });
    }

    render() {
        return super.render({
            meta: this.state.meta,
            customHeader: <></>, // this should be removed later
            inviewToRender: <div className="app">
                <div className="logo-container">
                    <AppLogo></AppLogo>
                </div>
                <p className="version">{VERSION_TAG}</p>
                <div className="search-container">
                    <SearchBarComponent preload={false} showSearchButton={true}/>
                    
                </div>
                
            </div>
        })
    }
}