import React from "react";
import "./SearchNewsIndexWidget.scss"
import DateHandler from "../../Common/Utility/DateHandler";
import TranslateUtility from "../../Common/Utility/TranslateUtility";


export default class SearchNewsIndexWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            result: props.result,
            keywords: props.keywords
        }

        this.onClick = this.onClick.bind(this);
    }

    replaceKeyWords() {
        let description = this.state.result.description;

        if (description) {
            this.state.keywords.forEach(word => {
                const regex = new RegExp(word, 'gi'); // 'gi' for case-insensitive global search
                description = description.replace(regex, `<strong>${word}</strong>`);
            });

            // Limit description length and ensure HTML tags are not split
            const truncatedDescription = description.slice(0, 140);

            // Check if the last index of '<' is less than the last index of '>'
            // to ensure closing tags are not split
            const lastIndexOfOpeningTag = truncatedDescription.lastIndexOf('<');
            const lastIndexOfClosingTag = truncatedDescription.lastIndexOf('>');

            if (lastIndexOfOpeningTag > lastIndexOfClosingTag) {
                // If the last index of '<' is greater than the last index of '>',
                // then there is an unclosed tag, so we find the last index of '>' after the last index of '<'
                const nextClosingTagIndex = truncatedDescription.indexOf('>', lastIndexOfOpeningTag);
                if (nextClosingTagIndex !== -1) {
                    // If a closing tag is found, truncate the description until that closing tag
                    description = truncatedDescription.slice(0, nextClosingTagIndex + 1);
                }
            } else {
                // If all tags are properly closed, use the truncated description
                description = truncatedDescription;
            }

            description += "...";
        }

        return description;
    }

    onClick = (e) => {
        e.preventDefault();
        const url = this.state.result.url;
        window.location.href = url;
    }


    render() {
        let displayTime;
        if(this.state.result.publicationDate) {
            const elapsedTime = DateHandler.getElapsedTime(this.state.result.publicationDate);

            if (elapsedTime.days > 0) {
                displayTime = new Date(this.state.result.publicationDate).toLocaleString();
            } else if (elapsedTime.hours > 0) {
                if(elapsedTime.hours > 1) {
                    displayTime = TranslateUtility.t("hours_ago", {
                        "hours": elapsedTime.hours
                    });
                } else {
                    displayTime = TranslateUtility.t("hour_ago", {
                        "hour": elapsedTime.hours
                    });
                }
            } else if (elapsedTime.minutes > 0) {
                displayTime = TranslateUtility.t("minutes_ago", {
                    "minutes": elapsedTime.minutes
                });
            } else {
                displayTime = TranslateUtility.t("just_now");
            }
    
        }
     
        return <div className="SearchNewsIndexWidget">
            <a className="link" onClick={this.onClick} href={this.state.result.url}>
                <div>
                    <p className="linkName">{this.state.result.publisher}</p>
                    <h4 className="title">{this.state.result.title}</h4>
                    <p className="description" dangerouslySetInnerHTML={{ __html: this.replaceKeyWords() }}></p>
                    <p className="date">{displayTime}</p>
                </div>
                {this.state.result.images.length > 0 && <img src={this.state.result.images[0].loc} alt='pic' />}
            </a>
        </div>

    }
}