import BrowserUtility from "./BrowserUtility";
import CookieStorage from "./Storage/CookieStorage";

import EnglishTrans from '../../Translations/english.json';
import TurkishTrans from '../../Translations/turkish.json';
import FrenchTrans from '../../Translations/french.json';

export default class TranslateUtility {

    static getLang() {
        if (CookieStorage.has('lang')) {
            return CookieStorage.get('lang');
        } else {
            return BrowserUtility.browserLanguage;
        }
    }


    static t(key, transMap = null) {
        const lang = TranslateUtility.getLang();
        let langFile = EnglishTrans;

        if (lang === 'en') {
            langFile = EnglishTrans;
        } else if (lang === 'tr') {
            langFile = TurkishTrans;
        } else if (lang === 'fr') {
            langFile = FrenchTrans;
        }

        if (langFile[key]) {
            return langFile[key].replace(/%\w+%/g, function(match) {
                // Remove the '%' symbols to get the variable name
                var variable = match.replace(/%/g, '');
        
                // Check if the variable exists in the transMap
                if (transMap.hasOwnProperty(variable)) {
                    // Replace the variable with its value from the transMap
                    return transMap[variable];
                } else {
                    // If the variable is not found, return the original match
                    return match;
                }
            })
        }
        // fallback 
        if (EnglishTrans[key]) {
            return EnglishTrans[key].replace(/%\w+%/g, function(match) {
                // Remove the '%' symbols to get the variable name
                var variable = match.replace(/%/g, '');
        
                // Check if the variable exists in the transMap
                if (transMap.hasOwnProperty(variable)) {
                    // Replace the variable with its value from the transMap
                    return transMap[variable];
                } else {
                    // If the variable is not found, return the original match
                    return match;
                }
            });
        }
        // at worse
        console.log('Missing translation', key);
        return key;
    }

}